.not-front{
    &.node-type-office-unit{

        .pane-page-title{
            margin-bottom: 1em;
        }

        //Index
        .jump-menu{
            //background-color: $wcm-bg-gray;
            border: 2px solid $wcm-border-gray;
            padding: .5em 1.25em;

            p{
                &::before{
                    font-family: 'fontello';
                    content: '\0e80f';
                    border-bottom: none;
                    color: $wcm-bright-orange;
                    margin-right: 1em;
                }
            }
        }

        //Policies and Forms
        .view-policies-and-forms{
            .item-list{
                h4{
                    color: $wcm-white;
                    background-color: $wcm-dark-gray;
                    padding: 1em;
                    font-family: $font-family-sans-serif;
                    font-size: $font-size-base;
                    font-weight: $headings-font-weight;
                    margin-bottom: .5em;
                }
                ul{
                    list-style: none;
                    list-style-type: none;
                    li{
                        margin-left: 0;

                        .views-field-field-file-link{
                            a{
                                margin-left: .5em;
                            }
                        }
                        .views-field-field-file-description{
                            margin-left: 1.75em;
                        }
                    }
                }
            }
        }

        //Contacts
        .pane-profiles-panel-pane-8{
            .views-row{
                margin-top: 1em;
                margin-bottom: 1em;
            }

            .views-field.display-name{
                font-weight: bold;
            }
            .views-field-field-professional-title{

            }
            .views-field-field-phone{
                .views-label{
                    font-weight: bold;    
                }
            }
            .views-field-field-email-address{
                .views-label{
                    font-weight: bold;    
                }
            }
        }

    }
}