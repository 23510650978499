.panel-reserach-home {
  @include clearfix;
  display: block;
  position: relative;
}

.panel-reserach-home #main-content {
  @include clearfix;
  max-width: 940px;
  @include breakpoint($lg) {
    max-width: 1180px;
  }
  margin: 0 auto;
}

.panel-reserach-home #upper-content {
  display: block;
  position: relative;
  margin: 0 auto;
  max-width: 940px;
  margin: 0 auto;
  @include breakpoint($lg) {
    max-width: 1180px;
  }
}

.homemain{
  max-width: 100%!important;
  padding: 0!important;
}

.panel-reserach-home #reserach-home-upper-left {
  @include breakpoint($sm) {
    box-sizing: border-box;
    width: 100%;
    float: left;
  }
}

.panel-reserach-home #reserach-home-top-left {
  @include breakpoint($sm) {
    box-sizing: border-box;
    width: 100%;
    padding: 0 10px;
    float: left;
  }
  @include breakpoint($md){
    width: 68%;
    padding: 0 95px 0 0;
  }
}

.panel-reserach-home #reserach-home-top-right {
  @include breakpoint($sm) {
    box-sizing: border-box;
    width: 100%;
    float: left;
    padding: 20px 10px 0 10px;
  }
  @include breakpoint($md){
    width: 32%;
    padding: 0;
  }
}

.panel-reserach-home #reserach-home-bottom-left {
  @include breakpoint($sm) {
    box-sizing: border-box;
    width: 33.33%;
    padding-right: 15px;
    float: left;
  }
}

.panel-reserach-home #reserach-home-bottom-center {
  @include breakpoint($sm) {
    box-sizing: border-box;
    width: 33.33%;
    float: left;
    padding-right: 15px;
  }
}

.panel-reserach-home #reserach-home-bottom-right {
  @include breakpoint($sm) {
    box-sizing: border-box;
    width: 33.33%;
    float: left;
  }
}

.panel-reserach-home {

  .home-text {
    text-align: center;
    font-size: 18px;
    padding: 20px 20px;
  }
  .section-title {
    border-top: 5px solid $wcm-red;
    text-align: center;

    a {
      color: $wcm-white;
      border-bottom: none;
      &:after {
        content: '\e80d';
        color: $wcm-yellow;
        font-size: 70%;
        padding-left: 10px;
        @include fontello();
      }
      &:hover, &:focus {
        border-bottom: 1px solid;
        // color: $wcm-yellow;
      }
    }

    span {
      display: inline-block;
      background: $wcm-red;
      color: #fff;
      font-size: 16px;
      font-weight: 700;
      text-transform: uppercase;
      padding: 5px 30px;
      letter-spacing: 2px;
    }
  }

  #section-news-wrapper {
    background: $wcm-bg-gray;
    padding-bottom: 40px;
  }

  .view-news {
    margin-top: 40px;
    position: relative;

    .home-news-feature__image {
      overflow: hidden;

      img {
        @include transition(all 0.3s ease 0s);

        &:hover {
          transform: scale(1.1);
        }
      }
    }

    .home-news-feature__details {
      font-weight: normal;
      font-size: 15px;

      a:hover {
        text-decoration: underline;
      }
    }

    .home-news-feature > .row.is-flex {
      display: block;
      @include breakpoint($sm) {
        display: flex;
        align-items: center;
      }
    }

    .home-news-feature__view-all {
      font-weight: normal;
      font-size: 15px;
      @include breakpoint($sm) {
        position: absolute;
        top: 0;
        right: 0;
        margin: 0;
      }
    }

    .view-teaser {
       font-weight: 600;
       font-size: 16px;

      .teaser-image {
        overflow: hidden;
        float: none;
        margin: 0 0 20px 0;

        img {
          @include transition(all 0.3s ease 0s);

          &:hover {
            transform: scale(1.1);
          }
        }
      }
    }
  }
}

.panel-reserach-home {

  .thumbnail-controller {
    display: none;
  }

  .mobile-pager {
    @include breakpoint( (min: 600px) ) {
      display: block;
    }
    @include breakpoint($sm) {
      display: none;
    }
  }

  #slide-counter {
    @include breakpoint( (min: 600px) ) {
      display: block;
    }
    @include breakpoint($sm) {
      display: none;
    }
  }

  .slide-text {
    background: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    opacity: 1;
    z-index: 10;
  }

  .slide-image {
    background: $wcm-black;

    img {
      opacity: 0.65;
    }
    /*&:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      z-index: 9;
      width: 100%;
      height: 100%;
      background: rgba(0,0,0,0.35);
    }*/
  }

  .slide-anchor {
    font: 0/0 a;
    display: none;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto 0 auto;
    bottom: -30px;
    width: 60px;
    height: 60px;
    border-bottom: none;
    z-index: 100;

    @include breakpoint($sm) {
      display: block;
    }

    &:hover {
      &:before {
        color: $wcm-white;
        background: $wcm-red;
      }
    }

    &:before {
      content: '\e80f';
      @include fontello();
      font-size: 25px;
      color: $wcm-dark-orange;
      width: 60px;
      height: 60px;
      line-height: 60px;
      display: block;
      position: absolute;
      background: $wcm-white;
      -webkit-border-radius: 50%;
      border-radius: 50%;
      box-shadow: 2px 2px 5px 1px $wcm-border-gray;
    }
  }

  .cycle-slideshow {
    @include breakpoint($sm) {
      margin-bottom: 30px;
    }

    .slide-title-text {
      @include breakpoint($xs) {
        width: 100%;
      }
      @include center();
      text-align: center;
      z-index: 10;

      h2 {
        color: $wcm-white;
        font-size: 25px;
        @include breakpoint($sm) {
          font-size: 45px;
        }
        margin: 0;
        text-shadow: 0 3px 9px #333333;

        a {
          color: $wcm-white;
          display: block;
          &::after {
            display: none;
          }
          &:focus {
            border-bottom: 1px solid !important;
          }
        }
      }
    }

    .cycle-pause {
      top: initial;
      bottom: 15px;
      left: 15px;
      width: 35px;
      height: 35px;
      line-height: 35px;
      opacity: 1;

      &:hover{
        background: $wcm-red;
      }
      /*position: absolute;
      display: block;

      color: $wcm-white;
      background-color: $wcm-dark-orange;
      z-index: 1005;
      cursor: pointer;*/

      /*&:before {
        content: '';
        position: relative;
        display: inline-block;
        left: 12px;
        width: 3px;
        height: 10px;
        background: #fff;
      }
      &:after {
        content: '';
        position: relative;
        display: inline-block;
        width: 3px;
        height: 10px;
        left: 15px;
        background: #fff;
      }
      &.paused {
        &:before {
          content: '\e80d';
          @include fontello();
          position: relative;
          background: transparent;
          left: 12px;
        }
        &::after {
          display: none;
        }
      }*/
    }

    .cycle-prev {
      @include breakpoint($sm) {
        display: block;
        width: 45px;
        height: 70px;
      }
      opacity: 1;
      background: none;
      &:hover {
        opacity: 1;
        &:before {
          background: $wcm-red;
        }
      }
      &:before {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        background: $wcm-dark-orange;
        width: 45px;
        height: 70px;
      }
      &:after {
        content: '\e810';
        @include fontello();
        position: absolute;
        top: 32%;
        transform: translateY(-50%, 50%);
        left: 15px;
        font-size: 25px;
        color: $wcm-white;
      }

    }
    .cycle-next {
      opacity: 1;
      @include breakpoint($sm) {
        display: block;
        width: 45px;
        height: 70px;
      }
      background: none;
      &:hover {
        opacity: 1;
        &:before {
          background: $wcm-red;
        }
      }
      &:before {
        content: '';
        display: block;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        background: $wcm-dark-orange;
        width: 45px;
        height: 70px;
      }
      &:after {
        content: '\e802';
        @include fontello();
        position: absolute;
        top: 32%;
        transform: translateY(-50%, 50%);
        right: 10px;
        font-size: 25px;
        color: $wcm-white;
      }
    }
  }
}
/* =Priority Highlights - Teasers
 ----------------------------------------------------------*/

 .highlights {
  margin-top: 30px;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-color: $wcm-med-gray;
  @include clearfix();
  @include breakpoint($sm) {
    display: flex;
  }
}

.highlights-mobile-nav {
  background: rgba(207,69,32,.9);
  z-index: 12;
  padding: 10px 30px 20px;
  position: relative;
  @include clearfix();
  @include breakpoint($sm) {
    display: none;
  }
  &:after {
    content: "";
    display: inline-block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 10px 0 10px;
    border-color: rgba(207,69,32,.9) transparent transparent transparent;
    position: absolute;
    bottom: -10px;
    right: 50%
  }
  .wayfinder--highlights {
    width: 80%;
    margin: 0 auto;
    button {
      width: 100%;
      text-align: left;
    }
    .selection-arrow span {
      top: 5px;
    }
    .highlights__nav--sm.dropdown-menu {
      width: 90%;
      a {
        border-bottom: none;
      }
    }
  }
}

.highlights__nav {
  display: none;
  @include breakpoint($sm) {
    display: block;
    float: left;
    width: 33.33333%;
    margin: 0;
    font-size: 18px;
    background: $wcm-dark-orange;
    color: $wcm-white;
    opacity: 0.9;
    z-index: 10;
    padding: 40px 30px;
  }
  @include breakpoint($md) {
    padding: 60px 30px 60px 60px;
  }
  li {
    list-style-type: none;
    padding: 0;
    border: 1px dotted;
    border-width: 1px 0 0;
    position: relative;
    @include breakpoint($md) {
      padding: 0;
    }
  }
  li:last-child {
    border-width: 1px 0;
  }
  a {
    display: block;
    padding: 10px 5px;
    border: none;
    color: $wcm-white;
    text-align: right;
    &.active,
    &:hover {
      background: $wcm-red;
    }
  }
}

.highlights__nav a.active {
  &:after {
    content: "";
    display: inline-block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 0 10px 10px;
    border-color: transparent transparent transparent $wcm-dark-orange;
    position: absolute;
    top: 15px;
    right: -40px;
  }
}

.highlights__teaser {
  display: none;
  position: relative;
  z-index: 11;
  cursor: pointer;
  @include breakpoint($xs) {
    min-height: 250px;
    padding: 50px;
  }
  @include breakpoint($sm) {
    float: left;
    width: 66.66666%;
  }
}

// Show first teaser on load
.highlights__teaser:nth-of-type(2) {
  display: block;
}

.highlights__text {
  color: $wcm-white;
  @include breakpoint($xs) {
    text-align: center;
  }
  @include breakpoint($sm) {
    width: 75%;
    padding: 0 0 0 60px;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%); /* Ch <36, Saf 5.1+, iOS, An =<4.4.4 */
    -ms-transform: translateY(-50%); /* IE 9 */
    transform: translateY(-50%); /* IE 10, Fx 16+, Op 12.1+ */
  }
}

.highlights__title {
  font-size: 42px;
  font-family: $wcm-bold;
  line-height: 1.2;
  padding: 0 0 10px;
  @include breakpoint($sm) {
    text-align: left;
  }
}

.highlights__title:after {
  @include breakpoint($xs) {
    content: '\e80d';
    padding-left: 10px;
    font-size: 40%;
    vertical-align: middle;
    @include fontello();
    text-decoration: none;
    line-height: 1.5;
    color: $wcm-yellow;
  }
}

.highlights__desc {
  font-size: 18px;
}

.highlights__indicator {
  display: none;
  @include breakpoint($sm) {
    display: block;
    position: absolute;
    height: 50%;
    top: 25%;
    right: 5%;
    border-left: 1px dotted $wcm-yellow;
    padding-left: 30px;
    z-index: 10;
    opacity: 0.7;
    transition: all 0.2s ease 0s;
  }
  &:after {
    content: "";
    position: relative;
    top: 25%;
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 40px 0 40px 40px;
    border-color: transparent transparent transparent $wcm-yellow;
  }
}

.highlights .highlight_mask {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.45);
  @include breakpoint($md) {
    transition: all 0.2s ease 0s;
  }
}

.highlights.is-hover {
  .highlights__indicator {
    @include breakpoint($md) {
      opacity: 1.0;
    }
  }
  .highlight_mask {
    @include breakpoint($md) {
      background: rgba(0,0,0,0.6);
    }
  }
}

.wayfinder {
  font-size: 12px;
  button {
    border: none;
    background: none;
    color: $wcm-white;
    border: 2px solid $wcm-white;
    border-radius: 10px;
    margin: 10px 0 0;
    padding: 4px 100px 4px 10px;
    &:focus {
      background: $wcm-white;
      color: $wcm-black;
    }
  }
  .selection-arrow {
    width: 45px;
    height: 20px;
    display: block;
    position: absolute;
    top: 16px;
    right: 1px;
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    border-left: 1px solid $wcm-border-gray;
      &:after {
        content: '\e80f';
        color: $wcm-white;
        @include fontello();
        display: block;
      }
  }
  .dropdown-menu li {
    padding: 2px 0;
    border-top: 1px solid $wcm-border-gray;
    &:first-child {
      border: none;
    }
  }
  .dropdown-menu > li > a:hover {
    color: $wcm-dark-orange;
  }
}

.wayfinder:hover,
.wayfinder.open {
  button {
    background: $wcm-white;
    color: $wcm-black;
  }
  .selection-arrow {
    border-left: 1px solid $wcm-bright-orange;
    &:after {
      color: $wcm-dark-orange;
    }
  }
}


/* =Home Events
----------------------------------------------------------*/
/*.home-events {
  position: relative;
  @include breakpoint($sm) {
    padding: 50px 0 0;
  }
}*/
.pane-news-panel-pane-1 {
  @include clearfix();
  padding-bottom: 40px;
  .view-news {
    margin-top: 0;
  }

  .view-header {
    text-align: right;
    padding-right: 20px;
    margin-bottom: 20px;
  }
  .view-teaser {
    @include breakpoint($xs) {
      margin: 0 auto 20px;
      max-width: 300px;
    }
  }
  .home-event__image {
    position: relative;
    overflow: hidden;
    background: $wcm-black;
    border-radius: 5px;
    &:hover {
      .home-event__date {
        display: none;
      }
      a:before {
        opacity: 0;
      }
      img {
        transform: scale(1.1);
      }
    }
    a:before {
      content: "";
      background: $wcm-red;
      display: block;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 10;
      opacity: 0.5;
      transition: opacity 0.3s ease-in-out;
    }
    img {
      width: 100%;
      transition: all 0.3s ease 0s;
    }
  }

  .home-event__date {
    position: absolute;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
    z-index: 100;
    color: $wcm-white;
    text-align: center;
    text-transform: uppercase;
    font-size: 24px;
    font-weight: 700;
    @include breakpoint($md) {
      font-size: 36px;
    }
  }

  .home-event__time {
    font-size: 13px;
    margin: 10px 0 5px;
    font-weight: 400;
    @include breakpoint($md) {
      font-size: 15px;
      margin: 20px 0 10px;
    }
  }

  .home-event__title {
    font-size: 14px;
    font-weight: 700;
    line-height: 1.5;
    @include breakpoint($md) {
      font-size: 16px;
    }
  }
}

///// Callouts
.panel-reserach-home {
  .main-content {
    margin-top: 25px !important;

    .doormat {
      .doormat-entry {
        background: $wcm-bg-gray;

        .doormat-entry__image {
          overflow: hidden;

          img {
            width: 100%;
            @include transition(all 0.3s ease 0s);
          }
        }
      }

      .doormat-entry__title {
        position: relative;
        padding: 0 20px;
        &:before {
          content: '\e802';
          position: absolute;
          top: -65px;
          width: 50px;
          height: 50px;
          left: 0;
          right: 0;
          margin: 0 auto;
          color: #e7751d;
          background: #f7f7f7;
          -webkit-border-radius: 50%;
          border-radius: 50%;
          font-size: 20px;
          text-align: center;
          @include fontello();
          line-height: 50px;
          display: inline-block;
          -webkit-transition: background-color 0.25s;
          transition: background-color 0.25s;
        }
        h3 {
          margin-top: 40px;
        }
        text-align: center;
        a {
          &:after {
            display: none;
          }
        }
      }

      .doormat-entry__description {
        padding: 0 20px 30px;
      }
    }
  }

  .middle-content {
    padding: 70px 0;
    margin-top: 30px;
    background: url("https://weill.cornell.edu/sites/default/files/featured_video_backgrounds/education-featured-video-background_0.png");
    background-size: cover;

    .faculty-callout__wrap {
      background: rgba(0, 0, 0, 0.6);
      // padding: 0 0 10px;
      // @include breakpoint($sm) {
      //   padding: 0 40px 60px;
      // }

      .faculty-callouts {
        width: 100%;
        margin: 30px 0 0 0;
        // padding: 0;
        padding: 0 0 10px;
        @include breakpoint($sm) {
          display: flex;
          padding: 0 40px 60px;
        }
      }

      .faculty-callouts li {
        max-width: 300px;
        margin: 0 auto;
        list-style-type: none;
        position: relative;
        @include breakpoint($sm) {
          max-width: none;
          vertical-align: top;
          position: relative;
          width: 25%;
          margin: 0;
          border:0 transparent solid;
          border-width: 0 5px;
          background-clip:padding-box;
        }
        @include breakpoint($md) {
          border-width: 0 20px;
        }

        @include breakpoint($sm) {
          width: 100%;
          border-width: 0 5px 0 5px;
        }
        @include breakpoint($md) {
          border-width: 0 20px;
        }

        .faculty-callout__summary {
          @include breakpoint($xs) {
            height: auto !important;
          }

          @include breakpoint($sm) {
            width: 25%;
            float: left;
            padding-right: 6.65px;
          }
          @include breakpoint($md) {
            padding-right: 26.6px;
          }
        }
      }

      .faculty-callout {
        position: relative;
        width: 100%;
        background: $wcm-dark-gray;
        margin: 0 0 20px;
        @include breakpoint($sm) {
          margin: 0;
          display: flex;
        }
        @include breakpoint($md) {
          // margin-bottom: -99999px;
          // padding-bottom: 99999px;
        }

        &:before {
          content: '';
          background: $wcm-dark-gray;
          display: block;
          width: 100%;
          height: 100%;
          position: absolute;
          -webkit-box-shadow: 3px 3px 10px 3px rgba(0,0,0,0.6);
          box-shadow: 3px 3px 10px 3px rgba(0,0,0,0.6);
        }
      }

      .faculty-callout__image {
        position: relative;
        overflow: hidden;
        // cursor: pointer;

        img {
          width: 100%;
          height: auto;
          // transition: all 0.3s ease 0s;
          // &:hover {
          //   transform: scale(1.1);
          // }
        }
      }

      .faculty-callout__identity {
        background: $wcm-dark-gray;
        color: $wcm-white;
        @include breakpoint($xs) {
          padding: 20px 20px;
        }
        padding: 20px 30px;
        position: relative;
        @include breakpoint($sm-only) {
          padding: 15px 20px;
        }
      }

      .faculty-callout__name {
        font-weight: 700;
        margin: 0 0 10px;
        line-height: 1.3;
      }

      /*.faculty-callout__title {
        font-size: 14px;
      }*/

      .faculty-callout__details {
        background: $wcm-dark-gray;
        color: $wcm-white;
        position: relative;
        @include breakpoint($sm) {
          margin: 0;
          width: 75%;
          float: left;
        }
      }

      .faculty-callout__text {
        margin: 10px 20px;
        @include breakpoint($sm) {
          padding: 0 20px 0 20px;
          margin: 0 0 0 0;
        }

        @include breakpoint($md) {
          padding: 0 20px 0 3px;
        }

        @include breakpoint($sm) {
          @include v-center();
          position: absolute;
        }
      }

      .faculty-callout__description {
        margin: 0 0 20px;
        a {
          color: $wcm-yellow;
        }
        @include breakpoint($md) {
          // font-size: 18px;
          margin: 0 20px 60px 0;
        }
        h3 {
          color: $wcm-white;
          margin-bottom: 20px;
        }
      }

      .faculty-callout__profile-links {
        @include breakpoint($sm) {
          position: absolute;
          bottom: 0;
          right: 0;
        }

        a + a {
          // &:first-child {
            @include breakpoint($sm) {
              &:before {
                content: '';
                position: absolute;
                left: 0;
                height: 70%;
                top: 15%;
                border: 0.5px solid $wcm-white;
                opacity: 0.5;
              }
            }
          // }
        }

        a {
          position: relative;
          display: block;
          // font-weight: 700;
          background: $wcm-dark-orange;
          color: $wcm-white;
          margin: 5px 0;
          padding: 10px 40px;
          border: none;
          text-align: center;
          @include breakpoint($sm) {
            display: inline-block;
            margin: 0 0px 0 0;
            float: left;
            // font-size: 18px;
          }

          // &:first-child {
          //   @include breakpoint($sm) {
          //     &:before {
          //       content: '';
          //       position: absolute;
          //       right: 0;
          //       height: 70%;
          //       top: 15%;
          //       border: 0.5px solid $wcm-white;
          //       opacity: 0.5;
          //     }
          //   }
          // }

          &:after{
            content: '' !important;
            background-image: url('/sites/all/themes/wcmc_research/images/external-link.svg');
            background-repeat: no-repeat;
            background-size: 12px 12px;
            // background-position: 15px 15px;
            margin-left: 6px;
            position: relative;
            width: 15px;
            height: 15px;
            top: 2px;

            // content: '\e80d';
            // padding-left: 10px;
            // font-size: 70%;
            // @include fontello();
          }
          &:hover {
            background: $wcm-red;
          }
        }
      }
    }
  } // middle-content

  .bottom-content {
    margin-top: 40px;
    margin-bottom: 20px;

    .pane-bundle-image {
      text-align: center;
      margin-bottom: 20px;

      .field-basic-image-image {
        overflow: hidden;
        img {
          margin-bottom: 0;

          @include transition(all 0.3s ease 0s);
        }

        &:hover {
          img {
            transform: scale(1.1);
          }
        }
      }

      .field-basic-image-image a {
        position: relative;
        border-bottom: none;

        &:after {
          content: '';
          width: 50px;
          height: 50px;
          display: block;
          background: $wcm-white;
          border-radius: 50%;
          position: absolute;
          bottom: -20px;
          left: 0;
          right: 0;
          margin: 0 auto;

          background-image: url('/profiles/wcmc/themes/wcmc_bootstrap/images/cta_icons/SVG/external-link.svg');
          background-repeat: no-repeat;
          background-size: 15px 15px;
          background-position: 17px 17px;
        }
      }

      .field-basic-image-caption {
        padding-top: 10px;

        a {
          &:hover {
            border-bottom: 1px solid $wcm-red !important;
          }
        }
      }
    }
  }
}
