.not-front.page-node-5489 {

  // Funding Opportunities
  div[class*="pane-facetapi-"] {

    position: relative;
    
    .facetapi-limit-link {
      display: none;
    }
    .item-list {
      ul {
        display: none;
        list-style: none;

        li {
          .facetapi-checkbox {
            margin-right: 5px;
          }
        }
      }
    }
    .pane-title {
      border-top: 1px solid $wcm-border-gray;
      font-family: $font-family-sans-serif;
      font-size: 14px;
      padding: 23px 10px 20px;
      margin-top: 0;
      color: $wcm-dark-orange;
      cursor: pointer;

      &:after {
        content: '+';
        float: right;
        font-size: 28px;
        line-height: 16px;
        font-weight: bold;
      }

      &.revealed {
        &:after {
          content: '-';
        }
      }
    }

    &.facet-submission-date-posted {
      .pane-title {
        border-bottom: 1px solid $wcm-border-gray;
      }
    }
    
    .facetapi-facetapi-checkbox-links {
      li {
        font-weight: bold;

        a {
          font-weight: normal;
          color: $wcm-black;
          border: none;
        }
      }
    }

  }

  .funding-opportunities-search {

    position: relative;
    margin-bottom: 20px;

    .views-widget-filter-search_api_views_fulltext {
      width: 100%;
      padding: 0;
      @include breakpoint($xs) {
        padding-right: 50px;
      }
      padding-right: 120px;

      label {
        font-size: 13px;
      }

      .form-item-search-text{
        input.form-text{
          border: 1px solid $wcm-border-gray;
          @include box-shadow(none);
          height: 50px;
          border-right: none;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
          max-width: none;
        }
      }
    }

    .views-submit-button {
      position: absolute;
      right: 0;
      bottom: 0;
      padding: 0;

      .form-submit {
        margin-top: 0;
        width: auto;
        height: 50px;
        background-color: $wcm-bright-orange;
        background-image: url(/sites/all/themes/wcmc_research/images/search-white.svg);
        background-size: 20px 20px;
        background-repeat: no-repeat;
        background-position: 15px center;
        border: none;
        color: $wcm-white;
        -webkit-border-radius: 2px;
        border-radius: 2px;
        font-size: 18px;
        padding-left: 45px;
        @include breakpoint($xs) {
          font-size: 0;
          padding-left: 30px;
        }
      }
    }

  }

  .pane-current-search-funding-opportunities {
    // margin-bottom: 20px; 
    @include breakpoint($xs) {
      padding-top: 15px;
    }
    // border-top: 1px solid $wcm-border-gray;
    border-bottom: 1px solid $wcm-border-gray;
  
    .current-search-item {
  
      &.current-search-item-results {
        font-size: 20px;
        border-bottom: 1px solid $wcm-border-gray;
        padding-bottom: 10px;
        padding-top: 10px;
        margin-bottom: 10px;
  
        span {
          font-weight: 700;
          font-size: 22px;
  
          &:last-child {
            color: $wcm-dark-orange;
          }
        }
      }
  
      &.current-search-item-active-items {
        display: inline-block;
      }
      &.current-search-item-reset {
        display: inline-block;
        padding-left: 25px;
        a {
          text-decoration: underline;
        }
      }
  
      .refined-results {
        font-size: 14px;
        &:before {
          content: 'Refined by: ';
          position: relative;
          font-size: 13px;
          font-weight: bold;
        }
        li {
          display: inline-block;
          font-weight: 700;
  
          a {
            position: relative;
            color: transparent;
            float: right;
            top: 2px;
            left: 2px;
            &:before {
              display: inline-block;
              content: '';
              background-image: url(/profiles/wcmc/themes/wcmc_bootstrap/images/cta_icons/SVG/close-circle.svg);
              background-size: 15px 15px;
              height: 15px;
              width: 15px;
              position: absolute;
              top: 2px;
            }
          }
        }
      }
    }
  }

  .funding-opportunities-sort {
    background: $wcm-bg-gray;
    padding: 15px 10px;
    .views-widget-filter-search_api_views_fulltext {
      display: none;
    }

    .form-item-sort-bef-combine{
      label {
        display: none;
      }
      font-size: 13px;
      display: block;
      border-bottom: 1px solid $wcm-border-gray;
      padding-bottom: 5px;
  
      .form-type-bef-link {
        display: inline;
        margin-right: 10px;
  
        a {
          color: $wcm-dark-gray;
          border-bottom: none;
        }
  
        &:last-child {
          padding-left: 10px;
          border-left: 1px solid $wcm-border-gray;
        }
      }

      .selected-edit-sort-bef-combine-field-sumbission-ext-deadline-asc, 
      .form-item-edit-sort-bef-combine-field-sumbission-ext-deadline-asc,
      .selected-edit-sort-bef-combine-field-submission-int-deadline-asc,
      .form-item-edit-sort-bef-combine-field-submission-int-deadline-asc {
        a{
          color: $wcm-dark-gray;
          border-bottom: none;
          
          &:after{
            color: $wcm-dark-orange;
            font-family: "fontello";
            content: '\e80f';
            padding-left: 10px;
            transition: all 0.25s;
          }
        }
      }
  
      .selected-edit-sort-bef-combine-field-sumbission-ext-deadline-desc,
      .form-item-edit-sort-bef-combine-field-sumbission-ext-deadline-desc,
      .selected-edit-sort-bef-combine-field-submission-int-deadline-desc,
      .form-item-edit-sort-bef-combine-field-submission-int-deadline-desc {
        a{
          font-weight: bold;
          border-bottom: none;
          
          &:after{
            color: $wcm-dark-orange;
            font-family: "fontello";
            content: '\e80b';
            font-weight: normal;
            padding-left: 10px;
            transition: all 0.25s;
          }
        }
      }

    }

    .views-widget-filter-field_submission_is_open {
      .form-item {
        display: inline;
        margin-right: 20px;
  
      }
    }

  }

  .pane-submissions-search-api-panel-pane-1{
    .view-header {
      margin-bottom: 30px;
      padding-bottom: 10px;
      padding-top: 20px;
      font-size: 14px;
    }
  
    .views-row {
      border-bottom: 1px solid $wcm-border-gray;
      padding-bottom: 20px;
    }
  
    .views-field-nothing {
      margin: 8px 0;
      font-size: 13px;
      font-weight: bold;
      a {
        margin-right: 15px;
      }
    }
  
    .views-field-field-sumbission-ext-deadline {
      font-size: 13px;
      font-weight: bold;
      display: inline;
    }
    .views-field-field-submission-int-deadline {
      font-size: 13px;
      font-weight: bold;
      display: inline;
      border-left: 1px solid $wcm-border-gray;
      padding-left: 20px;
      margin-left: 20px;
    }

  }

  .funding-opportunities-callout {
    margin-top: 20px;
    margin-bottom: 20px;
    background: $wcm-bg-gray;
    .pane-title {
      font-family: $wcm-regular;
      background: $wcm-dark-orange;
      color: $wcm-white;
      text-align: center;
      margin: 0;
      font-size: 16px;
      padding: 20px 0px;
    }
  
    .pane-content {
      padding: 0 20px 10px; 
  
      h3 {
        border-top: 1px solid $wcm-border-gray;
        padding-top: 20px;
        &:first-of-type {
          border-top: none;
          padding-top: 0;
        }
  
        a {
          &:after {
            content: '';
            background-image: url(/profiles/wcmc/themes/wcmc_bootstrap/images/cta_icons/SVG/external-link.svg);
            background-size: 16px 16px;
            width: 16px;
            height: 16px;
            margin-left: 5px;
          }
          &:hover {
            border-bottom: 1px solid !important;
          }
        }
      }
    }
  }

} //.not-front.page-node-5489
