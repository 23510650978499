html {
  overflow: -moz-scrollbars-vertical;
  overflow-y: scroll;
}

$cornell-red: #B31B1B;
$cornell-orange: #e87722;

a {
   outline: 0;

   &:focus {
     color: $wcm-red;
     border-bottom: 1px solid;
   }
}

.title-bar {
  a {
    color: $wcm-white;

    &:focus {
      border-bottom: 1px solid;
      outline: thin dotted !important;
      outline: 5px auto -webkit-focus-ring-color !important;
      outline-offset: -2px;
    }
  }
}

.breadcrumb > .active {
  color: $wcm-med-gray;
}

header .login a,
.brand__nav .navbar-nav > li.login > a{
  border-right: 1px solid $wcm-med-gray;
  line-height: 13px;
  margin-top: 31px;
  float: left;
  padding-right: 15px;

}

li.login {
  list-style: none;
    > a{
      padding-right: 15px;
      padding-left: 20px;
      background-image: none;
      @include breakpoint($sm){
        margin: 0;
        height: inherit;
      }

  }
}

span.login__usrname{
  padding-left: 15px;
  color: $wcm-med-gray;
  border-left: 1px solid $wcm-med-gray;
  @include breakpoint($sm){
    float: left;
    border-left: none;
    margin: 31px 0 0 0;
    font-size: 12px;
    height: 13px;
    line-height: 13px;
  }
}

#primary-nav .level-1 {
	width: auto;
  margin: 0 21.6px;
  padding: 16px 0;
  font-size: 14px;
  @include breakpoint($lg){
    margin: 0 31px;
    font-size: 16px;
  }

}

#drawer-nav .menu-mlid-1677.level-2{
  width: auto;
  padding-right: 30px;
}

.view-display-id-panel_pane_9{
  .field-content{
    height: 490px;
    overflow: hidden;
    display: block;
    width: 100%;
  }
}

.banholder{
  overflow: hidden;
  height: 490px;
  .teaser-image{
    display: none;
  }
}

.banimg{
  float: left;
  height: 490px;
  width: 100%;
  overflow: hidden;
  .panel-pane{
    position: absolute;
    top: 0;
  }

  .imgdiv{
    position: relative;
    display: block;
    margin: 0 auto;
    height: 735px;
    width: 100%;
    max-width: none;
    background-position: center center;
    background-image: url('../images/wcmholder.jpg');
  }
  .mask{
    float: left;
    width: 100%;
  }

  .topmask{
    background-color:rgba(0,0,0,0.25);
    position: relative;
    height: 340px;
    @include breakpoint ($sm){
      height: 376px;
    }
  }

  .bottommask{
    background-color:rgba(255,255,255,0.8);
    position: relative;
    height: 150px;
    @include breakpoint($sm){
      height: 114px;
    }
    transition: background-color .5s ease-in-out;
  }

  div.banimgcont{
    max-width: 940px;
    @include breakpoint($lg){
    	max-width: 1180px;
    }
    margin: 0 auto;
    display: block;
  }

  p{
    color: #2f3236;
    font-size: 18px;
    float: left;
    min-height: 114px;
    padding-top: 0px;
    @include  breakpoint($sm){
      padding-top: 25px;
    }

    box-sizing: border-box;
    line-height: 30px;
    font-family: "open-sans", sans-serif;
    margin:  0 10px;
    @include breakpoint($md){
      margin: 0;
    }
  }
  h2{
    width: 100%;
    float: left;
    color: #fff;
	  text-shadow: 1px 2px 5px rgba(0, 0, 0, 0.5);
    font-size: 32px;
	  font-weight:300;
    line-height:1.3;
    margin: 10px;
    @include breakpoint($sm){
      font-size: 42px;
    }
    @include breakpoint($md){
      margin: 10px 0;
    }
    a:after{
      display: none;
    }
  }
  p.red{
    display: block;
    float: left;
    width: auto;
    color: #fff;
    background-color: #b31b1b;
    line-height: 24px;
    height: 24px;
    min-height: 0;
    padding: 0 45px 0 49px;
    margin: 100px 0 0 10px;
  	font-size: 16px;
    @include breakpoint($xs){
      margin-left: 10px;
    }
    @include breakpoint($sm){
      margin: 157px 0 0 10px;
    }
    @include breakpoint($md){
      margin: 180px 0 0 0;
    }
    @include breakpoint($lg){
      margin: 222px 0 0 0;
    }
  }
  a{
    color: #fff;
    &:hover{
      text-decoration: none;
    }
  }
  a span{
    color: #000;
    &:hover{
      text-decoration: none;
    }
  }
}

.upper-content .pane-content{
  width: 100%;
  padding: 10px 10px 5px;
  box-sizing: border-box;
  @include breakpoint($sm){
    padding: 5px 0 25px 0;
  }

  a:hover {
  color:$cornell-orange;
  }

}

.upper-content .pane-content h2.pane-title{
  text-transform: capitalize;
  font-size: 14px;
  font-weight: bold;
  float: left;
  width: 100%;
  margin-top: 10px;
  margin-bottom:10px;
  a{
    margin: 0;
    color: #000;
    padding-left: 0;
    background-image: none;
    font-size: 1.6rem;
    line-height: 28px;
    min-height: 0;
    i{
      display: none;
    }
    &:after{
     line-height: 28px;
    }
  }

  a:hover {
    color:$cornell-orange;
    text-decoration:none;
  }
}

.upper-content .pane-content a{
	color: #000;
	font-size: 1.6rem;
	line-height: 28px;
	font-family: "open-sans", sans-serif;
	font-weight: 300;
	margin: 15px 0;
	float: left;
	width: 100%;
  min-height: 56px;
  &:hover{
    text-decoration: none;
  }
}

.upper-content .pane-content p a{
  font-size: 1.5rem;
  &:after{
    content: "";
    display: inline-block;
    position: relative;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-left: 5px solid #e67730;
    margin-left: 5px;
  }
}

.upper-content .pane-content p{
	margin: 0;
}

.upper-content .pane-content .teaser-image{
    margin: 0!important;
    width: 100%;
    a{
      margin: 0;
      img{
      border-radius: 0px;
      width: 100%;
      }
    }
 }

#reserach-home-top-left,
#reserach-home-top-right{
  margin: 0 10px;
  float: left;
  @include breakpoint($sm){
    margin: 0;
  }
  h3.pane-title{
  	color: $cornell-red;
    text-transform: capitalize;
    font-family: $headings-font-family;
    font-size: 24px;
	  padding-bottom:6px;
    display: inline;
    font-weight: 400;
  }
  h2.teaser-title{
    margin-top: 4px;
    margin-bottom: 0px;
    a{
      font-size: 16px;
      color: #555;
      font-weight: 700;
      font-family: "open-sans", sans-serif;
      &:after{
        line-height: 24px;
      }
      i{
        display: none;
      }
    }
    a:hover {
      color:$cornell-orange;
    }
  }

  .view-teaser{
    border-bottom: 1px solid #f6f5f3;
    margin-top: 20px;
  }

  .teaser-text p{
    font-size: 15px;
    line-height: 28px;
  }
  .views-field-field-date span, .field-content{
    font-size: 16px;
    color: $wcm-med-gray;
  }

  .pane-content{
    margin-top: 30px;
  }
  .view-footer{
    width: 100%;
    float: left;
    margin-bottom: 20px;
    a{
	    font-size: 12px;
	    font-size: 1.2rem;
      color: #fff;
	    float: left;
      margin: 20px 0 22px 10px;
	    i{
	    	display: none;
	    }
    }
  }
}


.pane-news-panel-pane-1{
  .views-field-nothing-1{
    float: left;
    height: 67px;
    width: 53px;
    margin: 10px 20px 44px 0;
    background-color: #dddddd;
    &:after{
      display: inline-block;
      content: "";
      width: 0;
      height: 0;
      position: relative;
      top: -38px;
      left: 53px;
      border-top: 7px solid transparent;
      border-bottom: 7px solid transparent;
      border-left: 7px solid #dddddd;
    }
    .dayno{
      color:  #555555;
      width: 100%;
      display: block;
      font-size:26px;
      font-weight:700;
      text-align: center;
	    margin-bottom:-10px;
    }
    .tlm{
      color:  #555555;
      width: 100%;
      display: block;
      text-align: center;
    }
  }
}

#reserach-home-bottom-center, #reserach-home-bottom-left, #reserach-home-bottom-right{
  padding-right: 0!important;
  overflow: hidden;
}

.lower-content{
  min-height: 268px;
  overflow: hidden;
}

.lower-content .fieldable-panels-pane{
  height: 268px;
}

.lower-content .fieldable-panels-pane a{
	  display: block;
	  box-sizing: border-box;
	  max-width: 240px;
	  color: #fff;
	  background-color: #b31b1b;
	  line-height: 40px;
	  height: 40px;
	  text-align: center;
	  margin: 0 auto;
	  border-radius: 2px;
	  font-size: 18px;
	  top: -162px;
	  position: relative;
	  -webkit-filter: grayscale(0);
	  filter: grayscale(0);
  }

  .lower-content .fieldable-panels-pane a:hover {
    text-decoration:none;
    background-color:$cornell-orange;
  }


  .corefac{
    background: url('../images/corefacilities.jpg') 0 0 no-repeat;
  }

  .trials{
    background: url('../images/clinicaltrials.jpg') 0 0 no-repeat;
  }

  .gift{
    background: url('../images/colorkid.jpg') 0 0 no-repeat;
  }

  .corefac, .trials, .gift{
    height: 268px;
    background-size:cover;
    -webkit-filter: grayscale(1);
    filter: grayscale(1);
    transition: all 0.5s;
    &:hover{
      -webkit-filter: grayscale(0);
      filter: grayscale(0);
      transform: scale(1.08, 1.08);
      cursor: pointer;
    }
  }


footer{
  padding-top: 0px;
	margin-top:10px;
}

.page-home footer {
  margin-top:0px;
}

nav.footer-nav{
  width: auto;
  float: none;
  padding-left: 0;
  ul{
    @include breakpoint($sm){
       padding-left: 20px;
    }
    line-height: 18px;
  }
}

.gotobtns{
  display: table-cell;
  vertical-align: middle;
  margin: 0 0 10px;
  width: 221px;
  height: 68px;
  line-height: 18px;
  border-bottom: 1px solid #ebebe8;
  border-radius: 2px;
  padding: 0;
  font-size: 15px;
  color: #cf4520;
  box-sizing: border-box;

  &:hover{
    text-decoration:none;
  }

  svg{
    fill: #e87722;
    color: #cf4520;
    height: 40px;
    width: 40px;
    margin-right: 15px;
    margin-left: 9px;
    vertical-align: middle;
  }

  &:after{
    font-size: 14px;
    line-height: inherit;
    content: '\e80f';
    text-decoration: none;
    color: $wcm-dark-orange;
    font-family: "fontello";
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    text-decoration: inherit;
    text-align: center;
    font-variant: normal;
    text-transform: none;
    line-height: 1em;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-transition: all 0.25s;
    -o-transition: all 0.25s;
    transition: all 0.25s;
    padding-left: 10px;
  }

}

.score svg{
  float: left;
}


  .resfac{
    margin-bottom: 10px;
    &:after{
      content: "\e802";
    }
    svg{
      width: 49px;
      height: 49px;
      margin-right: 11px;
      margin-left: 4px;
      vertical-align: middle;
    }
  }

  .howto{
    margin-bottom: 0px;
  }

  .bornone{
    border-bottom: none;
    margin-bottom: 0px;
  }
  .core{
    &:after{
      content: "\e802";
    }
    svg{
      width: 49px;
      height: 49px;
      margin-right: 11px;
      margin-left: 4px;
      vertical-align: middle;
    }
  }

  .cnbtn{
    padding-left: 59px;
    &:after{
      width: 12px;
      background-position: 0 0;
      transform: rotate(270deg);
    }
  }


  ul.acore{
  list-style: none;
  border: 1px solid #dddddd;
  border-radius: 3px;
  width: 221px;
  @include box-sizing(border-box);
  box-shadow: 3px 2px 5px #ebebe8;
  display: none;
  background-color: #fff;
  margin-top: 0px;
  padding: 0;
  li{
    //border-bottom: 1px solid #dddddd;
    min-height: 48px;
    width: 100%;
    display: block;
    div{
      display: table-cell;
      vertical-align: middle;
      //height: 48px;

      width: 220px;
      padding: 10px 0;
    }
    &:hover{
      background-color: $wcm-dark-orange;
      text-decoration:none;

    }
    &:hover a{
      color: #fff;
      text-decoration:none;
    }
    a{
      color: #000;
      font-size: 16px;
      line-height: 24px;
      padding: 0 5px 0 13px;
      width: 100%;
      display: block;
      &:hover{
        color: #fff;
      }
    }
  }
}

.pane-uuid-dff7abe9-1a7a-46fc-b7ad-420f008e6ce6 ul,
.pane-current-2 ul {
    list-style: none;
    padding-left: 0px;
    margin: 10px auto 30px;
    width: 221px;
    li{
      margin: 0 0 10px;
    }
}

.pane-uuid-dff7abe9-1a7a-46fc-b7ad-420f008e6ce6 ul ul,
.pane-current-2 ul ul {
  display: block;
  list-style: none;
  border: 1px solid #ddd;
  border-radius: 3px;
  width: 221px;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  box-shadow: 0 0 7px #f4dfdf;
  padding: 0;
  //display: none;
  background-color: #fff;
  margin-top: 0px;
  margin-right: 0px;
  margin-left: 0px;
  li{
    min-height: 48px;
    margin-bottom: 0;
    width: 100%;
    &:hover{
      background-color: $wcm-dark-orange;
      a{
        color: #fff;
      }
    }
    &:hover a{
      color: #fff;
      text-decoration:none;
    }
    a{
      color: #000;
      font-size: 16px;
      line-height: 24px;
      display: table-cell;
      vertical-align: middle;
      height: 48px;
      padding: 0 5px 0 13px;
      width: 220px;
      &:hover{
        color: #fff;
      }
    }
  }

}

/* Home page buttons */

.homepagebtns{
  width: 313px;
  height: 260px;
  margin: 0 auto;
@include breakpoint($lg){
     width: 413px;
    }
  a{
    float: left;
    width: 145px;
    height: 125px;
    border: 1px solid #ebebe8!important;
    font-size: 14px;
    text-align: center;
    box-sizing: border-box;
    margin-bottom: 9px;
    margin-right: 9px;
    color: #cd4629;
    svg.cta-icon{
      display: block;
      margin: 28px auto 5px;
      width: 50px;
      height: 50px;
    }
  }
  a:hover {
    text-decoration:none;
  }
  @include breakpoint($lg){
      a {
        width: 175px;
      }
    }

  .facul{
    padding-top: 0px;
    float: left;
  }


  .howtos{
    position: relative;
    &:before{
      position: absolute;
      top: 105px;
      left: 67px;
      content: " ";
      width: 0;
      height: 0;
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
      border-top: 7px solid $cornell-red;
      box-shadow: none;
    }

      @include breakpoint($lg){
      &:before{
      left: 79px;
      }
    }
  }

  .triangle{
    display: none;
    position: relative;
    top: 263px;
    left: 215px;
    width: 24px;
    height: 12px;
    background: url("../images/triangle.png") 0 0 no-repeat;
    z-index: 2;
  }

  ul{
    display: none;
    position: relative;
    width: 300px;
    width: 100%;
    max-width: 300px;
    @include breakpoint($lg){
     max-width: 360px;
    }
    margin-top: 5px;
    z-index: 1;
    list-style: none;
    border: 1px solid #ddd;
    border-radius: 3px;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    box-shadow: 0 0 7px #f4dfdf;
    padding: 0;
    background-color: #fff;
    margin-right: 0px;
    margin-left: 0px;

    li{
      list-style: none;
      width: 100%;
      min-height: 48px;
      margin-bottom: 0;
      &:hover{
        background-color: #cf4520;
      }
      a{
        width: 100%;
        box-shadow: none;
        border-width: 0 !important;
        padding: 0 0 0 13px;
        margin: 0 0 0 0;
        text-align: left;
        color: #000;
        font-size: 16px;
        line-height: 48px;
        display: table-cell;
        vertical-align: middle;
        height: 48px;
        &:hover{
          color: #fff;
        }
      }
    }
  }
}

.pane-uuid-94878d14-470d-4b83-828d-824e28ed169b span {
    float: left;
    min-height: 82px;
    line-height: 72px;
    color: #cd4629;
    text-transform: capitalize;
    font-size: 24px;
    background-color: #f7f7f7;
    border: 5px solid $wcm-bright-orange;
    border-radius: 3px;
    width: 100%;
    padding: 0 0 0 30px;
    margin: 30px 0 30px;
    font-weight: 300;
    svg{
      fill: $wcm-bright-orange;
      font-size: 40px;
      margin-right: 30px;
      top: 8px;
      position: relative;
    }
}

.view-display-id-panel_pane_3 .view-header{
  margin: 0 auto;
  width: 221px;
}

.cores-list{
  width: 221px;
  margin: 0 auto;
}


.brand__lockup{
  img{
    max-width: 481.5px;
  }
}

.block-menu-block .content{
  margin-bottom: 20px;
  @include breakpoint($sm){
    float: left;
  }
  @include breakpoint($md) {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
    -webkit-column-gap: 15px;
    -moz-column-gap: 15px;
    column-gap: 15px;
    width: 82%;
  }
}

.footer-address {
  //@include breakpoint($sm) {
    //height: 130px;
  //}
  @include breakpoint($md) {
    height: 268px;
  }
  display: block;

}

.footer-address__address{
  display: none;
  @include breakpoint($md) {
    display: block;
  }
}

.footer-contact__social-links a:before{
  @include breakpoint($sm){
    font-size: 24px;
  }
  @include breakpoint($md){
    font-size: 30px;
  }
}

.footer-contact__contact-us a{
  @include breakpoint($sm){
    font-size: 14px;
    padding: 20px 29px;
  }
   @include breakpoint($md){
    font-size: 20px;
    padding: 20px 48px;
   }
}


/* Cores Page */

.pane-core-facilities-panel-pane-1, .pane-core-facilities-panel-pane-2, .pane-core-facilities-panel-pane-5, .pane-core-facilities-panel-pane-6{
  margin-top: 40px;
  .pane-content{
    border-bottom: 1px solid #dddddd;
    margin-top: 20px;
  }
}

.pane-core-facilities-panel-pane-2 {
  margin-bottom: 40px;
}

.pane-core-facilities-panel-pane-1 a, .pane-core-facilities-panel-pane-2 a,
.pane-core-facilities-panel-pane-5 a, .pane-core-facilities-panel-pane-6 a{
    display: block;
    border-top: 1px solid #dddddd;
    height: 48px;
    line-height: 48px;
    width: 100%;
    padding-left: 14px;
    padding-right: 26px;
    box-sizing: border-box;
    font-size: 14px;
    cursor: pointer;
    //background-color: #FAFBFC;
    margin: 0 0;
    transition: all 1s;
    color: #cd4629;
    i{
      font-weight: bold;
      font-size: 26px;
      float: right;
      padding-top: 11px;
    }
}

/* Core node page */

.pane-custom.pane-3, .pane-custom.pane-2, .pane-node-field-lab-hours{

  margin-top: 40px;
  background-color: #F6F7F8;
  padding: 15px 20px 30px;
  border-top: 1px solid #EBEBE8;
  width: 100%;
  @include breakpoint($sm){
    width: 36%;
    box-sizing: content-box;
  }
  float: left;
  margin-bottom: 65px;
  h3{
    border-bottom: 1px solid #EBEBE8;
    font-size: 20px;
    color: #000;
    text-transform: none;
    padding-bottom:5px;
    margin-bottom:5px;
  }
  .field-item{
    font-size: 15px;
    line-height: 28px;
  }
  p{
    margin: 0;
  }
}

.pane-node-field-lab-hours{
  min-height: 128px;
  margin-top: 0;
  border-top: none;
  @include breakpoint($sm){
    padding-right: 19%;

    margin-top: 40px;
    border-top: 1px solid #EBEBE8;
  }
}

.pane-custom.pane-3, .pane-custom.pane-2{
  margin-bottom: 0;
  max-height: 132px;
  @include breakpoint($sm){
    margin-bottom: 65px;
  }
}



/* News Page*/

.recentawards{
  display: block;
  width: 50%;
  float: left;
  background-color: #f6f7f8;
  padding: 20px;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin-top: 10px;
  &:first-child{
    margin-right: 2%;
    width: 48%;
  }
  p{
    font-size: 14px;
    line-height: 25px;
  }
  a{
    color: #000;
  }


  h3{
    border-bottom: 1px solid #dddddd;
    color: #000;
    font-family: "open-sans", sans-serif;
    font-size:20px;
    margin-top: 0px;
    margin-bottom:10px;
    padding-bottom:10px;
    a{
      &:hover{
        text-decoration:none;
        color:#e87722;
      }
    }
  }
}

.viewfac:first-child{
  width: 100%;
  padding-right: 48%;
  margin-bottom: 10px;
}

.view-id-news{
  .view-teaser{
    padding-bottom:0px;
  }



  .teaser-image, .views-field-views-conditional-1 span.field-content{
    float: left;
    margin-top:5px;
    margin-right: 23px;
    margin-bottom: 15px;
    img{
      border-radius: 2px;
    }
  }
  .views-field-nothing{
    margin-bottom: 0px;
  }

  .views-field-field-news-category{
    a{
      color: #cf4520;
      font-size: 14px;
      background: url(../images/tag.png) 0 0 no-repeat;
      background-size: 16px 16px;
      padding-left: 25px;
      //top: -12px;
      position: relative;
    }
  }

  h2.teaser-title{
    font-size: 16px;
    margin-top: 0px;
    a {
      color:#555;
      line-height:1.5em;
      font-weight:bold;
      font-family: "open-sans", sans-serif;
      &:hover{
        color: $wcm-bright-orange;
      }
    }
    i{
      display: none;
    }
  }

  .view-footer{
    a{
      color: #000;
      font-size: 16px;
      margin: 0 0 0 70px;
      float: left;
      i{
        color: $cornell-red;
        font-size: 20px;
        padding-left: 5px;
      }
    }
  }
}

.pane-news-panel-pane-5{
  .views-field-views-conditional-1{
    a:after{
      display: none;
    }
  }
}

.pane-profiles-panel-pane-5{
  float: left;
  width: 100%;
  border-bottom: 1px solid #f6f5f3;
  font-family: "open-sans", sans-serif;
  padding-bottom: 18px;
  font-size: 15px;
  line-height: 24px;
  margin-top: 20px;
  .views-field-field-profile-picture{
    margin-right: 20px;
    float: left;
    img{
      border-radius: 2px;
      float: left;
    }
  }
  h3.display-name{
    font-size:17px;
    margin-top: 0;
    color: #565656;
  }
  .views-field-field-professional-title{
    font-weight: 600;
    padding: 4px 0;
  }
  .views-field-field-email-address{
    margin: 0;
    padding: 0;
  }
  .views-row{
    width: 100%;
    float: left;
    padding: 22px 0 18px;
    border-bottom: 1px solid #f1f1f1;
  }
  .field-content {
    color: $wcm-med-gray;
  }
}


.views-widget-filter-field_news_category_tid_selective{
  margin-bottom: 28px;
  margin-top: 17px;
  label{
    float: left;
    font-weight: 300;
    line-height: 44px;
    height: 44px;
    color: $wcm-med-gray;
    padding-right: 15px;
  }
  .views-widget{
    float: left;
  }
}

/*Liited sub grant*/
.view-limited-submission-grants label[for='edit-sort-bef-combine']{
  display: none;
}

.view-limited-submission-grants .views-widget-sort-sort_bef_combine .form-type-bef-link {
    padding-left: 0;
  }

.view-limited-submission-grants .views-field-nothing a{
  font-size: 13px;
}

.view-limited-submission-grants, .view-id-open_sub_grants{

  h5{
    margin-bottom: 7px;
    margin-top: 30px;
    font-family: "open-sans", sans-serif;
    font-weight: 300;
    font-size: 17px;
    font-weight: bold;
    margin-top: 22px;
    float: left;
    width: 100%;
    a{
      color: #555555;
      i{
        display: none;
      }
    }
  }

  .views-field-field-internal-deadline{
    color: #777777;
    margin-bottom: 4px;
    .views-label-field-internal-deadline{
      padding-right: 5px;
      float: left;
    }
  }

  .views-field-field-deadline, .views-field-field-deadline-date{
    color: #777777;
    margin-bottom: 4px;

    .views-label-field-internal-deadline{
      float: left;
      padding-right: 5px;
    }
  }

  p{
    margin-bottom: 9px;
  }

  .views-row{
    border-bottom: 1px solid #F4F6F4;
    padding-bottom: 18px;
  }

  label{
    font-weight: normal;
    font-size:13px;
    color: #777777;
  }

  .views-widget-filter-field_topic_tid{
    padding: .5em 11px 0 0;
  }

  span.select2{
    padding-bottom: 10px;
    width: 2002px!important;
    @include breakpoint($lg){
      width: 252px!important;
    }
  }



  .views-widget-sort-sort_bef_combine{
    width: 100%;
    padding-right: 43px;
    padding-top: 0px;

    a{
      font-size: 13px;
      font-family: "open-sans", sans-serif;
    }
    .form-type-bef-link a{
      padding: 30px 25px 13px 11px;
      float: left;
      color: #777777;
    }
    .selected a{
      color: #cf4520;
      padding-left: 0px;
    }
    div.form-type-bef-link{
      width: auto!important;
    }
  }

  .views-field-nothing{
    color: #F4F6F4;
  }
}

.view-id-open_sub_grants {
  label{
    float: left;
    padding: 35px 0 0 5px;

  }
}

.selected-edit-sort-bef-combine-field-deadline-value-asc a,
.selected-edit-sort-bef-combine-title-asc a,
.selected-edit-sort-bef-combine-field-deadline-date-value-asc a,
.form-item-edit-sort-bef-combine-title-asc a,
.form-item-edit-sort-bef-combine-field-deadline-value-asc a,
.form-item-edit-sort-bef-combine-field-deadline-date-value-asc a{
  &:after{
    font-size: 13px;
    line-height: inherit;
    content: '\e80f';
    text-decoration: none;
    color: #cf4520;
    font-family: "fontello";
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    text-decoration: inherit;
    text-align: center;
    font-variant: normal;
    text-transform: none;
    line-height: 1em;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-transition: all 0.25s;
    -o-transition: all 0.25s;
    transition: all 0.25s;
    padding-left: 10px;
  }
}

.form-item-edit-sort-bef-combine-title-asc a,
.form-item-edit-sort-bef-combine-field-deadline-value-asc a{
  &:after{
    color: #777777;
  }
}

.selected-edit-sort-bef-combine-field-deadline-value-desc a,
.selected-edit-sort-bef-combine-title-desc a,
.selected-edit-sort-bef-combine-field-deadline-date-value-desc a,
.form-item-edit-sort-bef-combine-title-desc a,
.form-item-edit-sort-bef-combine-field-deadline-value-desc a,
.form_item-edit-sort-bef-combine-field-deadline-date-value-desc a{
  &:after{
    font-size: 13px;
    line-height: inherit;
    content: '\e80b';
    text-decoration: none;
    color: #cf4520;
    font-family: "fontello";
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    text-decoration: inherit;
    text-align: center;
    font-variant: normal;
    text-transform: none;
    line-height: 1em;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-transition: all 0.25s;
    -o-transition: all 0.25s;
    transition: all 0.25s;
    padding-left: 10px;
  }
}

.form-item-edit-sort-bef-combine-title-desc a,
.form-item-edit-sort-bef-combine-field-deadline-value-desc a{
  &:after{
    color: #777777;
  }
}

.views-label-field-deadline{
  padding-right: 5px;
}

.view-limited-submission-grants{
  .views-widget-sort-sort_bef_combine .form-type-bef-link a{
    padding: 35px 45px 10px 0!important;
  }
}

.view-limited-submission-grants .views-widget-sort-sort_bef_combine .form-type-bef-link a,
.view-id-open_sub_grants .views-widget-sort-sort_bef_combine .form-type-bef-link a{
  padding: 35px 0 0 20px;
  font-size: 13px;
}

.view-id-open_sub_grants .views-widget-sort-sort_bef_combine .form-type-bef-link a{
  padding-right: 25px;
}

.views-exposed-widget.views-widget-filter-field_topic_tid, .views-exposed-widget.views-widget-filter-field_eligibility_type_tid{
  padding: .5em 20px 0 0;
}

.views-exposed-widget.views-widget-filter-field_deadline_value{
  padding: .5em 0px 0 0;
}

.container-inline-date > .form-item {
  margin-right: 0;
}

.view-limited-submission-grants .views-row{
  padding-bottom: 10px;
}


.view-id-limited_submission_grants .view-filters{
  background-color: #f7f7f7;
  padding: 0 14px;
  margin-bottom: 10px;
}

#edit-field-deadline-value-1-value-year, label[for='edit-field-deadline-value-1'] {
    display: none;
}

#edit-field-deadline-value-1-value-month{
  display: none;
}

label[for="field_deadline_value_1[value][year]"]{
  cursor: pointer;
}

.form-item-field-deadline-value-1-value-year, .form-item-field-deadline-value-1-op, .form-item-field-is-open-value {
  .date-year{
    float: left;
  }
  label{
    margin-right: 33px;
    cursor: pointer;
  }
  input{
    height: 20px;
    margin-top: 0;
    margin-right: 5px;
    &:checked + label {
      color: #cf4520;
    }
  }
  input,label{
    float: left;
    width: auto;
  }
}

label[for='edit-field-is-open-value']{
  display: none;
}

h1.osg_title{
  width: 100%;
  float: left;
}

.view-id-open_sub_grants .view-filters{
  width: 100%;
  float: left;
  margin-bottom: 20px;
}

#edit-field-deadline-value-1-wrapper, #edit-field-is-open-value-wrapper{
  width: 100%;
  border-top: 1px solid #dddddd;
}

.footer-statement{
  border-top: 5px solid #cf4520;
}


.pane-research-adlogin{
  margin-top: 20px;
}

a.button {
  display: inline-block;
  background-color: #cf4520;
  border-radius: 7px;
  height: 30px;
  line-height: 30px;
  padding: 0 14px;
  color: #fff;
  margin-right: 10px;
}

a.button:hover {
  background-color:$wcm-red;
  text-decoration:none;
}
a.button:focus {
  background-color:$wcm-red;
}

a.butmarbot{
  margin-bottom: 10px;
}

.view-reagent-and-equipment-sharing{
  .view-filter{
    width: 100%;
    float: left;
  }
}


table>tbody>tr:nth-child(odd)>td, table>tbody>tr:nth-child(odd)>th{
  background-color: #fff;
  vertical-align: middle;
}

table>thead>tr>th{
  background-color: #efefef;
}
table>tbody>tr:nth-child(even)>td, table>tbody>tr:nth-child(even)>th{
  background-color: #fbfbfb;
  vertical-align: middle;
}

table>thead>tr>th{
  border-bottom-width: 1px;
}

.information-sidebar{
  .pane-custom{
    width: 220px;
    margin: 0 auto;
    display: block;
  }
  table{

      font-size: 12px;

  }

  .table>thead>tr>th, .table>thead>tr>td, .table>tbody>tr>th, .table>tbody>tr>td, .table>tfoot>tr>th, .table>tfoot>tr>td{
    padding: 4px;
  }
}

.pane-uuid-503bf1f7-24e0-4f03-83d3-6ebbf235196b .fieldable-panels-pane div{
  float: left;
  width: 100%;
  figcaption{
    margin: 20px 0;
  }
}

.view-reagent-and-equipment-sharing{
  .views-exposed-form .views-widget-filter-field_reagent_equipment_value{
    float: left;
    width: 100%;
    border-top: 1px solid #ddd;
    padding: 0 0 0 0;
    margin-top: 30px;
    label{
      color: #777777;
    }
    .form-item-field-reagent-equipment-value{
      padding: 20px 38px 0 7px;
      float: left;
      &:first-child{
        display: none;
      }
    }
  }
}


/* LSG Single */

.pane-node-field-deadline, .pane-node-field-deadline-date, .pane-node-field-external-deadline{
  color: $cornell-red;
  font-size: 19px;
  font-family: "1898Sans-Bold", sans-serif;
  font-weight: 500;
  margin-top: 20px;
  div{
    display: inline-block;
  }
}

.field-collection-item-field-past-recipients{
  margin: 20px 0;
  float: left;
  width: 100%;
  .field-label, .date-display-single{
    display: inline;
    font-weight: normal;
  }
}

.pane-views-exp-profiles-panel-pane-3, .pane-uuid-f5066381-df18-477a-9582-5e8c2661fd85{
  width: 100%;
  background-color: #f7f7f7;
  float: left;
}

.pane-views-exp-profiles-panel-pane-3{
  margin-top: 25px;
  label{
    margin: 0 0 4px 8px;
    font-weight: normal;
  }
  .select2{
    width: 100%!important;
    max-width: 610px;
    margin: 0 0 0 8px;
  }
}

.pane-uuid-f5066381-df18-477a-9582-5e8c2661fd85{
  ul{
    padding: 20px 0 0  8px;
  a {
    padding-right:10px;
  }

    a.first{
      border-right: 1px solid #ccc;
      padding-right: 10px;
    }
  }
}
.pane-profiles-panel-pane-3{
  float: left;
  width: 100%;
  border-top: 1px solid #ddd;
  margin-top: 27px;
  .views-row{
     border-bottom: 1px solid #ddd;
  }
  .name-openclose{
    font-size: 13px;
    color: #cf4520;
    line-height: 49px;
    height: 49px;
    padding-left: 14px;
    font-weight: normal;
    margin-top: 0;
    cursor: pointer;
    span.opnbtn{
      float: right;
      font-size: 24px;
      line-height: 24px;
      border-left: 1px solid #ddd;
      color: $wcm-dark-orange;
      margin: 12px 0;
      padding: 0 17px;
    }
  }
  h2.blacktxt{
    color: #000;
    font-weight: bold;
  }
  h3.display-name{
    font-size: 13px;
    padding-left: 14px;
    color: #000;
    font-weight: bold;
    display: none;
  }
  div.field-content{
    font-size: 15px;
    padding-left: 14px;
    color: #000;
  }

  .open{
    background-color: #f7f7f7;
    color: #000;
  }

  .views-field-field-email-address{
    margin-bottom: 22px;
  }

  .views-field-field-lab-website{
    margin: 0 0 38px;
  }

  .views-field-field-professional-title, .views-field-field-email-address, .views-field-field-lab-website{
    display: none;
  }
}

.field-type-taxonomy-term-reference{
  margin: 0 0 20px 0;
}

.taxonomy-term-reference-0{
  margin-top: 16px;
  a{
    color: #cf4520;
    font-size: 14px;
    background: url(../images/tag.png) 5px 0 no-repeat;
    background-size: 16px 16px;
    padding-left: 30px;
  }
}
#drawer-nav .level-2{
  padding-right: 8px;
}

.pane-wcmc-contact-pane{
  margin-top: 20px;
  p{
    line-height: 28px;
  }
  
}


table {
    border: 1px solid #efefef;
    width: 100%;
}

table>thead>tr>th,
table>thead>tr>td,
table>tbody>tr>th,
table>tbody>tr>td,
table>tfoot>tr>th,
table>tfoot>tr>td{
  border: 1px solid #efefef;
  padding: 8px;
}

td.views-field-field-description p{
  margin-bottom: 0;
}


.contact-info{
  background-color: #ffffff;
  z-index: 99;
  position: relative;
  width: 220px;
  margin: 0 auto;
}


div.ctools-modal-content{
  width: auto;
}

div.caption-left{
  clear: both;
}


.view-display-id-panel_pane_4 table{
  margin-top: 10px;
}

.view-faq .item-list ul{
  list-style: none;
  margin: 10px 0 0 0!important;
  li{
    margin: 0;
  }
}

.pane-centers-and-institutes-panel-pane-1{
  .views-field-field-website, .views-field-field-faculty{
    float: left;
    margin-bottom: 20px;
  }
  .views-field-field-website{
    margin-right: 3px;
  }
  .views-field-title{
    clear: both;
  }
}

.view-administrative-units{
  border-top: 1px solid #ddd;
}

.pane-administrative-units-panel-pane-1{
  .views-row{
    display: block;
    float: left;
    p{
      margin-top: 14px;
    }
  }
}

img.administrative{
  float: left;
  margin: 20px 40px 20px 0;
  clear: both;
}

/* Dropdown menu/filter */
.select2-container
.select2-selection--single {
  padding-right:30px;
}

.views-field-field-pops-profile-link{
  display: none;
  margin-bottom: 38px;
}

.pane-node-body a{
  overflow-wrap: break-word;
  word-wrap: break-word;
}


//Calendar Styles
.view .date-nav-wrapper .date-prev{
  right: 119px;

}

.view .date-nav-wrapper .date-heading h3{
  text-align: left;
  padding-top: 8px;
}

.view-id-Events_Cal{
  margin-top: 20px;
}

.view .date-nav-wrapper .date-prev, .view .date-nav-wrapper .date-next{
  background-color: #fff;
  border-radius: 8px;
  a{
    background-color: $wcm-dark-orange;
    color: #fff;
    border: none;
    border-radius: 8px;
    padding: 0 35px;
    height: 30px;
    line-height: 30px;
    margin: 0;
  }
}

.calendar-calendar th.days{
  background-color: #f0f1f2;
}

.calendar-calendar .views-field-field-date-and-time{
  float: left;
  padding-right: 5px!important;
}

.calendar-calendar .month-view .full td.single-day div.monthview{
  border-radius: 0px;
  margin-bottom: 0px;
  padding-bottom: 0px;
  div.contents{
    padding-bottom: 9px;
  }
}

.calendar-calendar .month-view .full tr td.multi-day, .calendar-calendar .month-view .full tr td.single-day{
  border-radius: 0px;
  padding: 0;
  margin: 0;
}

.calendar-calendar .views-field-title{
  float: left;
}


.calendar-calendar .month-view div.content{
  margin-bottom: 9px;
}

.lf-ghost{
  height: auto!important;
}

div.modal-forms-modal-content .popups-close{
  background: url(../images/close.png) 0 0 no-repeat;
  &:hover{
    background: url(../images/close.png) 0 0 no-repeat;
  }
}

.close{
  opacity: 1;
  &:hover{
    opacity: 1;
  }
}

.view-content .calendar-calendar{
  margin-bottom: 20px;
}


#reserach-home-upper-left{
  border-bottom: 1px solid #f1f1f1;
  margin-bottom: 10px;
  .view-teaser{
    width: 100%;
    @include breakpoint($sm){
      width: 33.3333%;
      float: left;
      padding-left: 5px;
      padding-right: 5px;
      &:first-child{
        padding-left: 0;
        padding-right: 10px;
      }
      &:last-child{
        padding-right: 0;
        padding-left: 10px;
      }
    }
  }
}

.calendar-calendar .week-view div.single-day .i_0.md_0 .view-item .calendar,
.calendar-calendar .week-view div.single-day .i_0.md_1 .view-item .calendar,
.calendar-calendar .week-view div.single-day .i_5 .view-item .calendar,
.calendar-calendar .week-view div.single-day .i_0.md_0 .view-item .calendar{
  width: 100%!important;
  border: 0;
  border-radius: 0;
  margin-left: 0;

}

.calendar-calendar .week-view .full div.calendar-agenda-hour, .calendar-calendar .day-view .full div.calendar-agenda-hour, .calendar-calendar .week-view .full div.calendar-agenda-items, .calendar-calendar .day-view .full div.calendar-agenda-items{
  height: 74px;
}

.calendar-calendar div.single-day .d_4 .view-item .calendar {
    height: 73px;
}

.calendar-calendar .week-view div.single-day .half-hour, .calendar-calendar .day-view div.single-day .half-hour{
  height: 37px;
}

.calendar-calendar .week-view div.single-day .half-hour, .calendar-calendar .day-view div.single-day .half-hour{
  border-bottom: none;
}

.calendar-calendar .week-view .full div.item-wrapper, .calendar-calendar .day-view .full div.item-wrapper{
   margin-top: -37px;
}

.calendar-calendar div.single-day .o_0 .view-item {
  top: 0px;
}

.calendar-calendar div.single-day .o_1 .view-item {
  top: 18px;
}

.calendar-calendar div.single-day .o_2 .view-item {
  top: 37px;
}

.calendar-calendar div.single-day .o_3 .view-item {
  top: 55px;
}

.calendar-calendar div.single-day .o_4 .view-item {
  top: 73px;
}

.calendar-calendar div.single-day .d_1 .view-item .calendar {
  height: 18px;
}

.calendar-calendar div.single-day .d_2 .view-item .calendar {
  height: 37px;
}

.calendar-calendar div.single-day .d_3 .view-item .calendar {
  height: 55px;
}

.calendar-calendar div.single-day .d_4 .view-item .calendar {
  height: 74px;
}

.calendar-calendar div.single-day .d_5 .view-item .calendar {
  height: 92px;
}

.calendar-calendar div.single-day .d_6 .view-item .calendar {
  height: 111px;
}

.calendar-calendar div.single-day .d_7 .view-item .calendar {
  height: 129px;
}

.calendar-calendar div.single-day .d_8 .view-item .calendar {
  height: 148px;
}

.calendar-calendar div.single-day .d_9 .view-item .calendar {
  height: 166px;
}

.calendar-calendar div.single-day .d_10 .view-item .calendar {
  height: 185px;
}

.calendar-calendar div.single-day .d_11 .view-item .calendar {
  height: 203px;
}

.calendar-calendar div.single-day .d_12 .view-item .calendar {
  height: 222px;
}

.calendar-calendar div.single-day .d_13 .view-item .calendar {
  height: 240px;
}

.calendar-calendar div.single-day .d_14 .view-item .calendar {
  height: 259px;
}

.calendar-calendar div.single-day .d_15 .view-item .calendar {
  height: 277px;
}

.calendar-calendar div.single-day .d_16 .view-item .calendar {
  height: 296px;
}

.calendar-calendar div.single-day .d_17 .view-item .calendar {
  height: 314px;
}

.calendar-calendar div.single-day .d_18 .view-item .calendar {
  height: 333px;
}

.calendar-calendar div.single-day .d_19 .view-item .calendar {
  height: 351px;
}

.calendar-calendar div.single-day .d_20 .view-item .calendar {
  height: 370px;
}

.calendar-calendar div.single-day .d_21 .view-item .calendar {
  height: 388px;
}

.calendar-calendar div.single-day .d_22 .view-item .calendar {
  height: 407px;
}

.calendar-calendar div.single-day .d_23 .view-item .calendar {
  height: 425px;
}

.calendar-calendar div.single-day .d_24 .view-item .calendar {
  height: 444px;
}

.calendar-calendar div.single-day .d_25 .view-item .calendar {
  height: 462px;
}

.calendar-calendar div.single-day .d_26 .view-item .calendar {
  height: 481px;
}

.calendar-calendar div.single-day .d_27 .view-item .calendar {
  height: 499px;
}

.calendar-calendar div.single-day .d_28 .view-item .calendar {
  height: 518px;
}

.calendar-calendar div.single-day .d_29 .view-item .calendar {
  height: 536px;
}

.calendar-calendar div.single-day .d_30 .view-item .calendar {
  height: 555px;
}

.calendar-calendar div.single-day .d_31 .view-item .calendar {
  height: 573px;
}

.calendar-calendar div.single-day .d_32 .view-item .calendar {
  height: 592px;
}

.calendar-calendar div.single-day .d_33 .view-item .calendar {
  height: 610px;
}

.calendar-calendar div.single-day .d_34 .view-item .calendar {
  height: 626px;
}

.calendar-calendar div.single-day .d_35 .view-item .calendar {
  height: 647px;
}

.calendar-calendar div.single-day .d_36 .view-item .calendar {
  height: 666px;
}

.calendar-calendar div.single-day .d_37 .view-item .calendar {
  height: 684px;
}

.calendar-calendar div.single-day .d_38 .view-item .calendar {
  height: 700px;
}

.calendar-calendar div.single-day .d_39 .view-item .calendar {
  height: 721px;
}

.calendar-calendar div.single-day .d_40 .view-item .calendar {
  height: 740px;
}

.calendar-calendar div.single-day .d_41 .view-item .calendar {
  height: 490px;
}

.calendar-calendar div.single-day .d_42 .view-item .calendar {
  height: 502px;
}

.calendar-calendar div.single-day .d_43 .view-item .calendar {
  height: 514px;
}

.calendar-calendar div.single-day .d_44 .view-item .calendar {
  height: 526px;
}

.calendar-calendar div.single-day .d_45 .view-item .calendar {
  height: 538px;
}

.calendar-calendar div.single-day .d_46 .view-item .calendar {
  height: 550px;
}

.calendar-calendar div.single-day .d_47 .view-item .calendar {
  height: 562px;
}

.calendar-calendar div.single-day .d_48 .view-item .calendar {
  height: 574px;
}

.calendar-calendar div.single-day .d_49 .view-item .calendar {
  height: 586px;
}

.calendar-calendar div.single-day .d_50 .view-item .calendar {
  height: 598px;
}

.calendar-calendar div.single-day .d_51 .view-item .calendar {
  height: 610px;
}

.calendar-calendar div.single-day .d_52 .view-item .calendar {
  height: 622px;
}

.calendar-calendar div.single-day .d_53 .view-item .calendar {
  height: 634px;
}

.calendar-calendar div.single-day .d_54 .view-item .calendar {
  height: 646px;
}

.calendar-calendar div.single-day .d_55 .view-item .calendar {
  height: 658px;
}

.calendar-calendar div.single-day .d_56 .view-item .calendar {
  height: 670px;
}

.calendar-calendar div.single-day .d_57 .view-item .calendar {
  height: 682px;
}

.calendar-calendar div.single-day .d_58 .view-item .calendar {
  height: 694px;
}

.calendar-calendar div.single-day .d_59 .view-item .calendar {
  height: 706px;
}

.calendar-calendar div.single-day .d_60 .view-item .calendar {
  height: 718px;
}

.calendar-calendar div.single-day .d_61 .view-item .calendar {
  height: 730px;
}

.calendar-calendar div.single-day .d_62 .view-item .calendar {
  height: 742px;
}

.calendar-calendar div.single-day .d_63 .view-item .calendar {
  height: 754px;
}

.calendar-calendar div.single-day .d_64 .view-item .calendar {
  height: 766px;
}

.calendar-calendar div.single-day .d_65 .view-item .calendar {
  height: 778px;
}

.calendar-calendar div.single-day .d_66 .view-item .calendar {
  height: 790px;
}

.calendar-calendar div.single-day .d_67 .view-item .calendar {
  height: 802px;
}

.calendar-calendar div.single-day .d_68 .view-item .calendar {
  height: 814px;
}

.calendar-calendar div.single-day .d_69 .view-item .calendar {
  height: 826px;
}

.calendar-calendar div.single-day .d_70 .view-item .calendar {
  height: 838px;
}

.calendar-calendar div.single-day .d_71 .view-item .calendar {
  height: 850px;
}

.calendar-calendar div.single-day .d_72 .view-item .calendar {
  height: 862px;
}

.calendar-calendar div.single-day .d_73 .view-item .calendar {
  height: 874px;
}

.calendar-calendar div.single-day .d_74 .view-item .calendar {
  height: 886px;
}

.calendar-calendar div.single-day .d_75 .view-item .calendar {
  height: 898px;
}

.calendar-calendar div.single-day .d_76 .view-item .calendar {
  height: 910px;
}

.calendar-calendar div.single-day .d_77 .view-item .calendar {
  height: 922px;
}

.calendar-calendar div.single-day .d_78 .view-item .calendar {
  height: 934px;
}

.calendar-calendar div.single-day .d_79 .view-item .calendar {
  height: 946px;
}

.calendar-calendar div.single-day .d_80 .view-item .calendar {
  height: 958px;
}

.calendar-calendar div.single-day .d_81 .view-item .calendar {
  height: 970px;
}

.calendar-calendar div.single-day .d_82 .view-item .calendar {
  height: 982px;
}

.calendar-calendar div.single-day .d_83 .view-item .calendar {
  height: 994px;
}

.calendar-calendar div.single-day .d_84 .view-item .calendar {
  height: 1006px;
}

.calendar-calendar div.single-day .d_85 .view-item .calendar {
  height: 1018px;
}

.calendar-calendar div.single-day .d_86 .view-item .calendar {
  height: 1030px;
}

.calendar-calendar div.single-day .d_87 .view-item .calendar {
  height: 1042px;
}

.calendar-calendar div.single-day .d_88 .view-item .calendar {
  height: 1054px;
}

.calendar-calendar div.single-day .d_89 .view-item .calendar {
  height: 1066px;
}

.calendar-calendar div.single-day .d_90 .view-item .calendar {
  height: 1078px;
}

.calendar-calendar div.single-day .d_91 .view-item .calendar {
  height: 1090px;
}

.calendar-calendar div.single-day .d_92 .view-item .calendar {
  height: 1102px;
}

.calendar-calendar div.single-day .d_93 .view-item .calendar {
  height: 1114px;
}

.calendar-calendar div.single-day .d_94 .view-item .calendar {
  height: 1128px;
}

.calendar-calendar div.single-day .d_95 .view-item .calendar {
  height: 1140px;
}

.calendar-calendar div.single-day .d_96 .view-item .calendar {
  height: 1152px;
}


.evntlink{
  line-height: 2.0;
  margin: 25px auto 0 ;
}
.caltabs{
  margin-top: 15px;
}

.views-widget-filter-field_event_location_tid_selective .select2-container{
  min-width: 210px;
}

.views-exposed-form .views-widget-filter-field_event_location_tid_selective{
  padding: 0 0 10px 0;
}

label[for='edit-field-event-location-tid-selective']{
  float: left;
  margin: 0 20px 0 0;
  line-height: 50px;
}

#related-content-sidebar{
  @include breakpoint($md){
    width: 353px
  }
}

.views-widget-filter-field_event_location_tid_selective{
  float: none;
  .views-widget{
    float: left;
  }

}

#views-exposed-form-Events-Cal-panel-pane-1{
  .views-submit-button{
    padding: 0;
  }
  .views-exposed-widgets{
    margin-bottom: 0px;
  }
}

.pane-views-exp-events-cal-panel-pane-1{
  margin-bottom: 0!important;
}

.calendar-calendar .week-view .full .days {
    width: 14%;
}

th.margin-right, td.margin-right{
  display: none;
  width: 0;
}

.calendar-calendar .week-view .full td:nth-child(2){
   width: 14.1%;
}

.calendar-calendar td .inner div.day a{
   pointer-events: none;
   cursor: default;
   color: inherit;
   font-weight: normal;
}

.calendar-calendar .month-view .full td.date-box.today, .calendar-calendar .month-view .full tr td.single-day.today,
.calendar-calendar .month-view .full tr td.today, .calendar-calendar .month-view .full tr.odd td.today, .calendar-calendar .month-view .full tr.even td.today {
    border-color: #e87722;
}

#edit-submit-open-sub-grants, .eventsbtn,
#views-exposed-form-profiles-panel-pane-4 .form-submit{
  border-radius: 0;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  background-color: $wcm-bright-orange;
  border-color: $wcm-bright-orange;
  padding-left: 45px;
  margin: 0;
  background: url("../images/svg/search.svg") 14px 8px no-repeat $wcm-bright-orange;
  background-size: 22px 22px;
  &:hover{
    background-color: $wcm-dark-orange;
  }
}


#views-exposed-form-profiles-panel-pane-4{
  .views-exposed-form .views-exposed-widget{
    padding-right: 0;
  }
  .form-submit{
    margin-top: 16px;
  }
}


.view-id-open_sub_grants .views-submit-button{
  padding: 0;
  float: left;
  height: auto;
  margin: 16px 0 0 0;
}

#edit-combine-wrapper .views-widget{
  float: left;
}

#edit-combine, .eventstxt{
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right-width: 0;
  padding-right: 40px;
  float: left;
  margin: 16px 0 0 0;
}

.view-id-profiles table {
  th.active>a{
    color: $wcm-dark-orange;
    &:after{
      content: "";
    }
  }
  a.active{
    color: #000;
    float: left;
    &:after{
      font-size: 13px;
      line-height: inherit;
      content: '\e80b';
      text-decoration: none;
      font-family: "fontello";
      font-style: normal;
      font-weight: normal;
      display: inline-block;
      text-decoration: inherit;
      text-align: center;
      font-variant: normal;
      text-transform: none;
      line-height: 1em;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      -webkit-transition: all 0.25s;
      -o-transition: all 0.25s;
      transition: all 0.25s;
      padding-left: 10px;
    }
    img{
      display: none;
    }
    div.asc{
      float: right;
      &:after{
        font-size: 13px;
        line-height: inherit;
        content: '\e80b';
        text-decoration: none;
        font-family: "fontello";
        font-style: normal;
        font-weight: normal;
        display: inline-block;
        text-decoration: inherit;
        text-align: center;
        font-variant: normal;
        text-transform: none;
        line-height: 1em;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        -webkit-transition: all 0.25s;
        -o-transition: all 0.25s;
        transition: all 0.25s;
        padding-left: 10px;
      }
    }
    div.desc{
      float: right;
      &:after{
        font-size: 13px;
        line-height: inherit;
        content: '\e80f';
        text-decoration: none;
        font-family: "fontello";
        font-style: normal;
        font-weight: normal;
        display: inline-block;
        text-decoration: inherit;
        text-align: center;
        font-variant: normal;
        text-transform: none;
        line-height: 1em;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        -webkit-transition: all 0.25s;
        -o-transition: all 0.25s;
        transition: all 0.25s;
        padding-left: 10px;
      }
    }
  }
}

div.noimg{
  width: 100%;
  height: 215px;
  background-color: #f1f1f2;
  color: $cornell-red;
  font-family: "1898Sans-Bold",sans-serif;
  font-size: 40px;
  line-height: 215px;
  text-align: center;
  span{
    color: $wcm-bright-orange;
  }
}

div.noimg.nwspg{
  width: 117px;
  height: 129px;
  line-height: 129px;
  border-radius: 2px;
  font-size: 30px;
}

.eventstxt{
  max-width: 300px;
  margin-top: 26px;
  margin-bottom: 4px;
}
.eventsbtn{
  color: #fff;
  margin-top: 26px;
  margin-bottom: 4px;
}

h1 a:after, .h1 a:after, h2 a:after,
.h2 a:after, h3 a:after, .h3 a:after,
h4 a:after, .h4 a:after, h5 a:after,
.h5 a:after, h6 a:after, .h6 a:after{
  vertical-align: middle;
}

.view-id-news.view-display-id-panel_pane_10{
  .view-teaser{
    margin-top: 20px;
  }
}

.pane-node-field-source-link{
    .field-label{
      float: left;
  }
}

.pane-node-field-featured-image{
  figure.hero-image img{
    max-width: 100%;
    width: 100%;
  }
}

.news-featured-image{
  figure.hero-image img{
    width: auto;
  }
}

td.views-field-field-area-of-interest{
  min-width: 170px;
}


.ctools-collapsible-handle.clsbtn h2:after{
    content: '-';
}

.ctools-collapsible-container .ctools-toggle-collapsed{
    background: none;
}

.ctools-toggle{
    display: none;
}

.ctools-collapsible-content{
  padding: 0 10px;
}

/* ctools collapsible box END */

.node-type-submissions, .node-type-funding-opportunities {
  .pane-node-field-submission-faqs {
    clear: both;
    margin-bottom: 20px;
    .pane-title {
      margin-bottom: 10px;
    }
  }

  .pane-node-field-sumbission-ext-deadline, .pane-node-field-submission-int-deadline {
    font-size: 18px;
    font-family: $wcm-bold;
    color: $wcm-dark-gray;
    display: inline;

    div {
      display: inline;
    }
  }

  .pane-node-field-sumbission-ext-deadline + .pane-node-field-submission-int-deadline {
    border-left: 1px solid $wcm-border-gray;
    margin-left: 15px;
    padding-left: 20px;
  }

  .pane-node-body {
    padding-top: 15px;
  }

  .pane-node-field-past-recipients {
    @include clearfix();
    margin-bottom: 20px;
    .field-collection-item-field-past-recipients {
      width: 50%;
      margin: 10px 0;

      .content div:first-child {
        font-weight: bold;

        &.field-label {
          font-weight: normal;
        }
      }

      &:nth-child(odd) {
        clear: left;
      }
    }
  }

  .pane-node-field-submission-discipline {
    position: relative;
    padding-left: 60px;
    padding-top: 10px;
    display: inline-block;
    padding-right: 30px;
    &:before {
      display: block;
      content: '';
      width: 50px;
      height: 50px;
      position: absolute;
      left: 0;
      top: 0;
      background: url(/profiles/wcmc/themes/wcmc_bootstrap/images/cta_icons/SVG/labs.svg) center center no-repeat;
      background-size: 30px 30px;
      border-radius: 50%;
      @include box-shadow(0px 0px 8px 2px $wcm-border-gray);
    }

    ul {
      li {
        &.taxonomy-term-reference-0 {
          margin-top: 0;
        }
        display: inline-block;
        margin-right: 3px;
        &:after{
          content: ', ';
        }
        &:last-child {
          &:after {
            content: '';
          }
        }
      }
    }
  }

  .pane-node-field-submission-eligibilitytype {
    position: relative;
    padding-left: 60px;
    padding-top: 10px;
    display: inline-block;
    padding-right: 30px;
    &:before {
      display: block;
      content: '';
      width: 50px;
      height: 50px;
      position: absolute;
      left: 0;
      top: 0;
      background: url(/profiles/wcmc/themes/wcmc_bootstrap/images/cta_icons/SVG/education.svg) center center no-repeat;
      background-size: 30px 30px;
      border-radius: 50%;
      @include box-shadow(0px 0px 8px 2px $wcm-border-gray);
    }

    ul {
      li {
        &.taxonomy-term-reference-0 {
          margin-top: 0;
        }
        display: inline-block;
        margin-right: 3px;
        &:after{
          content: ', ';
        }
        &:last-child {
          &:after {
            content: '';
          }
        }
      }
    }
  }

}

.panel-research-search {
  .main-sidebar {
    .views-exposed-widget {
      width: 100%;
      float: none;

      .select2-container {
        width: 100% !important;
        margin-right: 10px;
      }

      .fieldset-wrapper {
        margin-left: 0;
        margin-bottom: 0;
        padding: 0;

        .form-checkboxes {
          height: auto;
          border: none;
          box-shadow: none;

          .form-control {
            width: auto;
            height: auto;
            border: none;
            box-shadow: none;
            display: inline-block;
          }
        }
      }
    }
  }

  .pane-uuid-dff7abe9-1a7a-46fc-b7ad-420f008e6ce6 {
    ul {
      width: 100%;
      text-align: center;
      li {
        margin: 0 10px;
        display: inline-block;
        text-align: left;
        position: relative;
        &:after {
          content: '';
          position: absolute;
          border-right: 1px solid $wcm-border-gray;
          height: 35px;
          right: 0;
          top: 15px;
        }

        &:last-child {
          &:after {
            display: none;
          }
        }

        ul {
          width: 220px;
          top: 65px;
          position: absolute;

          li {
            margin: 0;
          }
        }

        .gotobtns {
          border-bottom: none;
        }
      }
    }
  }
}


.main-content {
  a:empty {
    &:after {
      display: none;
    }
  }
}

#general-loader {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background: #000 url('../images/ajax-loader-big.gif') no-repeat center center;
  opacity: 0.6;
}

.pane-achievements-panel-pane-1 {
  .view-achievements {

    .views-row {
      .views-field-nothing {
        .views-row {
          margin-bottom: 20px;

          &:nth-child(4n+1) {
            clear: left;
          }
        }
      }
    }
    .awardee-name {
      font-weight: bold;
    }
    .awardee-title {
      font-size: 13px;
    }
  }
}

.pagination > .disabled > span {
  color: $wcm-med-gray;
}

.btn-primary {
  &:hover, &:focus {
    background-color: $wcm-red;
    border-color: $wcm-red;
  }
}

.search-button-span{
  .btn-primary {
    &:hover, &:focus {
      background-color: transparent;
      border-color: transparent;
    }
  }
}

/* =Pinned Header
----------------------------------------------------------*/

// Highlight Discover Global Link
.wcm-global-links {
  li:nth-child(2) {
    background: $wcm-white;
    a {
      color: $wcm-dark-orange;
      border: none;
      &:focus {
        color: $wcm-white;
      }
    }
  }
}

/* =Default Select Elements
----------------------------------------------------------*/

.form-select {
  max-width: none;
  height: 50px;
  border: 1px solid $wcm-border-gray;
  box-shadow: none;
  appearance: none;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cpath d='M207 381.5L12.7 187.1c-9.4-9.4-9.4-24.6 0-33.9l22.7-22.7c9.4-9.4 24.5-9.4 33.9 0l154.7 154 154.7-154c9.4-9.3 24.5-9.3 33.9 0l22.7 22.7c9.4 9.4 9.4 24.6 0 33.9L241 381.5c-9.4 9.3-24.6 9.3-34 0z' fill='%23cf4520'/%3E%3C/svg%3E");
	background-repeat: no-repeat;
	background-position: right 15px top 50%;
  background-size: 20px 20px;
  padding-right: 50px;
}

/* =Mini Site Elements
----------------------------------------------------------*/

.mini-site-menu-wrapper {
  margin-bottom: 10px;
}

div.mini-site-title {
  margin-top: 45px;
  text-align:center;
}
